import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useDispatch } from 'react-redux';
import { GameSwitcherItem } from './game-switcher-item';
import { SetGameHover } from '../actions/Main.actions';

import './game-switcher.scss';

interface IProps {
  type?: string;
  doroMode?: boolean;
}

export const GameSwitcher: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <div className={`game-switcher ${props.type ? 'popular' : 'all'}`}>
      <>
        {props.type === 'popular' ? (
          <>
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('exilium'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_gfl.webp"
                  alt="Girls' Frontline 2: Exilium"
                />
              }
              gamePath="/gfl-exilium/"
              gameName="Girls' Frontline 2: Exilium"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('hsr'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_hsr.webp"
                  alt="Honkai: Star Rail"
                />
              }
              gamePath="/star-rail/"
              gameName="Honkai: Star Rail"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('ww'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_ww.webp"
                  alt="Wuthering Waves"
                />
              }
              gamePath="/wuthering-waves/"
              gameName="Wuthering Waves"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('zzz'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_zzz.webp"
                  alt="Zenless Zone Zero"
                />
              }
              gamePath="/zenless/"
              gameName="Zenless Zone Zero"
            />
          </>
        ) : (
          <>
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('afk'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_afk.webp"
                  alt="AFK Journey"
                />
              }
              gamePath="/afk-journey/"
              gameName="AFK Journey"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('endfield'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_ae.webp"
                  alt="Arknights: Endfield"
                />
              }
              gamePath="/arknights-endfield/"
              gameName="Arknights: Endfield"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('ash'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_ash.webp"
                  alt="Ash Echoes"
                />
              }
              gamePath="/ash-echoes/"
              gameName="Ash Echoes Global"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('cs'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_cs.webp"
                  alt="Counter Side"
                />
              }
              gamePath="/counter-side/"
              gameName="Counter Side"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('es'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_es.webp"
                  alt="Eversoul"
                />
              }
              gamePath="/eversoul/"
              gameName="Eversoul"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('exilium'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_gfl.webp"
                  alt="Girls' Frontline 2: Exilium"
                />
              }
              gamePath="/gfl-exilium/"
              gameName="Girls' Frontline 2: Exilium"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('nikke'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_nikke.webp"
                  alt="NIKKE"
                />
              }
              gamePath="/nikke/"
              gameName="Goddess of Victory: NIKKE"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('hsr'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_hsr.webp"
                  alt="Honkai: Star Rail"
                />
              }
              gamePath="/star-rail/"
              gameName="Honkai: Star Rail"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('lc'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_limbus.webp"
                  alt="Limbus Company "
                />
              }
              gamePath="/limbus-company/"
              gameName="Limbus Company"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('reverse'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_reverse.webp"
                  alt="Reverse 1999"
                />
              }
              gamePath="/re1999/"
              gameName="Reverse 1999"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('solo'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_solo.webp"
                  alt="Solo Leveling: Arise"
                />
              }
              gamePath="/solo-leveling/"
              gameName="Solo Leveling: Arise"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('ww'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_ww.webp"
                  alt="Wuthering Waves"
                />
              }
              gamePath="/wuthering-waves/"
              gameName="Wuthering Waves"
            />
            <GameSwitcherItem
              onMouseOver={() => dispatch(SetGameHover('zzz'))}
              gameImage={
                <StaticImage
                  src="../../../images/generic/cover_zzz.webp"
                  alt="Zenless Zone Zero"
                />
              }
              gamePath="/zenless/"
              gameName="Zenless Zone Zero"
            />
          </>
        )}
      </>
    </div>
  );
};
